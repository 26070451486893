import React, {memo, useEffect} from 'react';
import {graphql} from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';

import PageWrapper from '../containers/PageWrapper';
import OrderReportForm from '../modules/OrderReportForm';

import UnderlineHeading from '../components/UnderlineHeading';
import FloatingAnchor from '../components/FloatingAnchor';

export const query = graphql`
  query {
    eaaReady: file(relativePath: { eq: "eaa/ready.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    eaaRequirements: file(relativePath: { eq: "eaa/requirements.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    eaaBenefits: file(relativePath: { eq: "eaa/benefits.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    eaaSeo: file(relativePath: { eq: "eaa/seo.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    eaaReasons: file(relativePath: { eq: "eaa/reasons.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;


const Eaa = ({data}) => {
  const {t} = useTranslation();
    useEffect(() => {
        const handleScroll = () => {

            if (document.getElementById('floatingAnchor')) {

                let formHeight = 0;
                let footerHeight = 0;
                if (document.getElementById("orderReportForm")) {
                    formHeight = document.getElementById("orderReportForm").getBoundingClientRect().height;
                }
                if (document.getElementById("footer")) {
                    footerHeight = document.getElementById("footer").getBoundingClientRect().height;
                }
                if (document.body.scrollHeight - document.body.offsetHeight - (formHeight + footerHeight) > window.scrollY) {
                    document.getElementById('floatingAnchor').style.display = 'flex';
                } else {
                    document.getElementById('floatingAnchor').style.display = 'none';
                }

            }
        }
        window.addEventListener('scroll', handleScroll);

    })
  return (<PageWrapper
      title="EAA Directive"
      description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
      urlPath="eaa"
      keywords={['coderylabs', 'eaa', 'directive', 'eu directive']}
    >
  <FloatingAnchor id="floatingAnchor" to="#orderReportForm" title={t('Order free report')} />

    <EaaSectionTitle>
      <UnderlineHeading>{t('eaa directive')} "European Accessibility Act"</UnderlineHeading>
    </EaaSectionTitle>

    <EaaSectionBgDark id="eaa-ready-selector">
      <EaaContentTextLeft>
        <EaaText>
          <EaaTitle>
              {t('eaa ready')}
          </EaaTitle>
          <EaaDescription>
              <div dangerouslySetInnerHTML={{__html: t('eaa ready module desc')}}/>
          </EaaDescription>
        </EaaText>
        <ImageContainer>
          <Img fluid={data.eaaReady.childImageSharp.fluid} alt="EAA - Czy strona Twojej firmy jest gotowa"/>
        </ImageContainer>
      </EaaContentTextLeft>
    </EaaSectionBgDark>

    <EaaSectionBgLight id="eaa-requirements-selector">
      <EaaContentTextRight>
        <EaaText>
          <EaaTitle>
              {t('eaa requirements')}
          </EaaTitle>
          <EaaDescription>
              <div dangerouslySetInnerHTML={{__html: t('eaa requirements module desc')}}/>
          </EaaDescription>
        </EaaText>
        <ImageContainer>
          <Img fluid={data.eaaRequirements.childImageSharp.fluid} alt="EAA - O jakie wymagania chodzi"/>
        </ImageContainer>
      </EaaContentTextRight>
    </EaaSectionBgLight>

    <EaaSectionBgCeladon id="eaa-benefits-selector">
        <EaaContentTextLeft>
            <EaaText>
                <EaaTitle>
                    <Trans>eaa benefits</Trans>
                </EaaTitle>
                <EaaDescription>
                    <div dangerouslySetInnerHTML={{__html: t('eaa benefits module desc')}}/>
                </EaaDescription>
            </EaaText>
            <ImageContainer>
              <Img fluid={data.eaaBenefits.childImageSharp.fluid} alt="Benefits"/>
            </ImageContainer>
        </EaaContentTextLeft>
    </EaaSectionBgCeladon>

    <EaaSectionBgLight id="eaa-seo-selector">
        <EaaContentTextRight>
            <EaaText>
                <EaaTitle>
                    <Trans>eaa seo</Trans>
                </EaaTitle>
                <EaaDescription>
                    <div dangerouslySetInnerHTML={{__html: t('eaa seo module desc')}}/>
                </EaaDescription>
            </EaaText>
            <ImageContainer>
                <Img fluid={data.eaaSeo.childImageSharp.fluid} alt="Seo"/>
            </ImageContainer>
        </EaaContentTextRight>
    </EaaSectionBgLight>

    <EaaSectionBgCeladon id="eaa-reasons-selector">
        <EaaContentTextLeft>
            <EaaText>
                <EaaTitle>
                    <Trans>eaa reasons</Trans>
                </EaaTitle>
                <EaaDescription>
                    <div dangerouslySetInnerHTML={{__html: t('eaa reasons module desc')}}/>
                </EaaDescription>
            </EaaText>
            <ImageContainer>
                <Img fluid={data.eaaReasons.childImageSharp.fluid} alt="Extended Webcontent Selector"/>
            </ImageContainer>
        </EaaContentTextLeft>
    </EaaSectionBgCeladon>

    <EaaSectionTargetForm>
        <EaaSectionBgLight>
            <EaaContentTextCenter>
                <EaaTitle>
                    <Trans>Order free report</Trans>
                </EaaTitle>
                <OrderReportForm/>
            </EaaContentTextCenter>
        </EaaSectionBgLight>
    </EaaSectionTargetForm>
  </PageWrapper>
  );
}

const EaaSectionTargetForm = styled.section`
    margin-top: -100px;
    padding-top: 100px;
`;


const EaaSectionTitle = styled.section`
  padding: 30px 15px 15px 15px;

  @media screen and (min-width: 1040px) {
    padding: 50px 30px 30px 30px;
  }
`;


const EaaSectionBgDark = styled.section`
  width: 100%;
  position: relative;
  background: #000;
  color: #FFF;
  padding: 20px 0 20px 0;

  @media screen and (min-width: 1040px) {
    padding: 80px 0;
  }
  @media screen and (min-width: 1200px) {
    padding: 80px 0;
  }
`;

const EaaSectionBgLight = styled.section`
  width: 100%;
  position: relative;
  background: #FFF;
  color: #000;
  padding: 15px 0;

  @media screen and (min-width: 1040px) {
    padding: 80px 0;
  }
  @media screen and (min-width: 1200px) {
    padding: 80px 0;
  }
`;

const EaaSectionBgCeladon = styled.div`
  width: 100%;
  position: relative;
  background: rgba(48, 193, 103, 0.08);
  color: #000;
  padding: 15px 0;

  @media screen and (min-width: 1040px) {
    padding: 30px 0;
  }
  @media screen and (min-width: 1200px) {
    padding: 80px 0;
  }
`;

const EaaContentTextLeft = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-flow: column-reverse;
  align-items: center;
  margin: 15px 0;
  position: relative;

  @media screen and (min-width: 1040px) {
    flex-direction: row;
    max-width: 1040px;
    margin: 0 auto;
  }
  @media screen and (min-width: 1200px) {
    position: inherit;
    max-width: 1200px;
    margin: 0 auto;
  }
`;

const EaaContentTextRight = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin: 15px 0;
  position: relative;

  @media screen and (min-width: 1040px) {
    margin: 0 auto;
    flex-direction: row-reverse;
    max-width: 1040px;
  }
  @media screen and (min-width: 1200px) {
    position: inherit;
    margin: 0 auto;
    max-width: 1200px;
  }
`;

const EaaContentTextCenter = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px auto;
  position: relative;

  @media screen and (min-width: 1040px) {
    width: 100%;
    margin: 0 auto;
    max-width: 1040px;
  }
  @media screen and (min-width: 1200px) {
    margin: 0 auto;
    max-width: 1200px;
  }
`;



const EaaText = styled.div`
  text-align: left;
  margin: 0;
  width: 100%;

  @media screen and (min-width: 1040px) {
    width: 50%;
  }
`;

const EaaTitle = styled.div`
  margin: 15px;
  font-size: 2rem;

  @media screen and (min-width: 1040px) {
    margin: 0 0 30px 0;
  }
`;
const EaaDescription = styled.div`
  margin: 15px;
  font-size: 1.3rem;
  font-weight: 300;

  @media screen and (min-width: 1040px) {
    margin: 0;
  }
`;

const ImageContainer = styled.div`
  width: 90%;
  max-width: 600px;

  @media screen and (min-width: 1040px) {
    position: inherit;
    width: 50%;
  }

`;

export default memo(Eaa);


