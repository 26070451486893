import React, {memo} from 'react';
import axios from 'axios';
import {Link} from 'gatsby';
import {Formik, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';

import {Trans} from 'gatsby-plugin-react-i18next';
import {useTranslation} from "react-i18next"


import Text from '../components/Form/Text';
import Checkbox from '../components/Form/Checkbox';
import SubmitButton from '../components/Form/SubmitButton';
import SubmitPopup from '../components/Form/SubmitPopup';
import styled from "styled-components";


const initialValues = {
  url: '',
  email: '',
  phone: '',
  privacyPolicy: false,
};

const submitForm = async (values, {setSubmitting, resetForm, setStatus}) => {
  try {
    const {status} = await axios.post(`${process.env.API_URL}/eaa`, values);

    setSubmitting(false);

    if (status === 200) {
      resetForm();
      setStatus('SUCCESS');
      return;
    }

    setStatus('ERROR');
  } catch (ex) {
    setStatus('ERROR');
    setSubmitting(false);
  }
};

const OrderReportForm = ({showTopics = false}) => {
  const {t} = useTranslation()

  const FormSchema = Yup.object().shape({
    url: Yup.string().required(<Trans>Please provide an url address</Trans>),
    email: Yup.string().email(<Trans>Please provide an email</Trans>).required(<Trans>Please provide a properly formatted email address</Trans>),
    phone: Yup.string().required(<Trans>Please provide a phone number</Trans>),
    privacyPolicy: Yup.boolean().oneOf([true], <Trans>Please accept Privacy Policy</Trans>).required(<Trans>Please accept Privacy Policy</Trans>),
  });

  return (
    <Formik initialValues={initialValues} onSubmit={submitForm} validationSchema={FormSchema}>
      {({status, setStatus, isSubmitting}) => (
        <Form id="orderReportForm">
          <Text name="url" placeholder={t('Url')}/>

          <Text type="email" name="email" placeholder={t('Email')}/>

          <Text name="phone" placeholder={t('Phone number')}/>

          <Checkbox id="privacyPolicy" name="privacyPolicy">
            <Trans>contact form terms link</Trans>{' '}
            <Link to="/privacy-policy" title={t('Privacy Policy')}>
              <Trans>contact form privacy policy</Trans>
            </Link>
          </Checkbox>

          <SubmitButton disabled={isSubmitting}><Trans>Send</Trans></SubmitButton>

          <SubmitPopup
            status={status}
            onCloseHandler={() => {
              setStatus(null);
            }}
          />
        </Form>
      )}
    </Formik>
  );
};

export default memo(OrderReportForm);
